import React from 'react'
import { graphql } from 'gatsby'
// import Moment from 'react-moment';
import styled from '@emotion/styled'
// import _M from 'moment';
import Header from '../components/Header'
import MainWrapper from '../containers/MainWrapper'
import SEO from '../components/SEO'


const IndexWrapper = styled.div`
  text-align: center;
  font-family: 'Source Sans Pro','Roboto','Helvetica','Arial',sans-serif;
  font-size: 18px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 2rem 0;
  }
  > a > img {
    display: block;
    margin: 0 auto;
    max-height: calc(100vh - 150px);
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      max-height: auto;
      max-width: 100%;
    }
  }
  a {
    display: block;
    padding: 1rem 0;
    &.mainImageLink {
      padding: 0;
    }
    &.label {
      img {
        opacity: .7;
        max-width: 200px;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  ul {
    margin: 1rem 0 0 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      padding: 0 1.2rem;
      list-style-type: none;
      @media (max-width: ${props => props.theme.breakpoints.m}) {
        padding: 0 .5rem;
      }
      a {
        img {
          opacity: .7;
          max-height: 50px;
          transition: .2s ease-out;
          &:hover {
            opacity: 1;
          }
          @media (max-width: ${props => props.theme.breakpoints.m}) {
            max-height: 25px;
          }
        }
      }
    }
  }
`

const Index = (props) => {
  const { data: { homepage } } = props;
  const mainImage = homepage.data.image.localFile.childImageSharp.fluid.srcWebp;
  const mainImageLink = homepage.data.lien_image.url;
  const links = homepage.data.liens;
  
  return (
    <React.Fragment>
      <SEO />
      <Header isIndex={true} location={props.location} />
      <MainWrapper>
        <IndexWrapper>
          <a rel="noreferrer" href={mainImageLink} target="_blank" className="mainImageLink">
            <img alt="Alexx and the Mooonshiners" src={mainImage} />
          </a>
          <ul>
            {
              links.map((item, index) => {
                return (
                  <li key={`item-${index}`}>
                    <a rel="noreferrer" href={item.lien.url} target="_blank">
                      <img alt={`item-${index}`} src={item.image1.localFile.childImageSharp.fluid.srcWebp} />
                    </a>
                  </li>
                )
              })
            }
          </ul>
        </IndexWrapper>
      </MainWrapper>
    </React.Fragment>
  )
}

export default Index;
export const pageQuery = graphql`
  query Homepage {
    homepage: prismicHomepage {
      data {
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        lien_image {
          url
        }
        liens {
          image1 {
            alt
            localFile {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          lien {
            url
          }
          titre {
            text
          }
        }
      }
    }
  }
`